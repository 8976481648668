export default {
	value: [
		{
			field: "id",
			hide: true,
		},
		{
			field: "check",
			headerName: "",
			checkboxSelection: true,
			headerCheckboxSelection: true,
			headerCheckboxSelectionFilteredOnly: true,
			minWidth: 50,
			maxWidth: 50,
			flex: 0,
		},
		{
			field: "price",
			headerName: "Цена",
			wrapText: true,
			minWidth: 140,
			maxWidth: 200,
			flex: 0,
		},
		{
			field: "status",
			headerName: "Статус",
			minWidth: 170,
			maxWidth: 170,
			flex: 0,
			cellRenderer: "OrderApprove",
		},
		{
			field: "products",
			headerName: "Товары",
			minWidth: 120,
			maxWidth: 120,
			wrapText: true,
			cellRenderer: "ProductsRenderer",
		},
		{
			field: "user",
			headerName: "Пользователь",
			minWidth: 200,
			wrapText: true,
		},
		{
			field: "email",
			headerName: "Email",
			minWidth: 180,
			wrapText: true,
			filter: "CustomTextFilter",
			filterParams: {
				filterName: "Поиск по email",
			},
		},
		{
			field: "company",
			headerName: "Компания",
			minWidth: 120,
		},
		{
			field: "paid_at",
			headerName: "Время оплаты",
			width: 180,
			flex: 0,
			sortable: true,
		},
		{
			field: "created_at",
			headerName: "Создан",
			width: 180,
			flex: 0,
			sortable: true,
		},
		{
			field: "user_id",
			headerName: "",
			minWidth: 200,
			cellRenderer: "ButtonRepurchase",
		},
	],
}