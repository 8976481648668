<template>
  <div v-if="orders" class="orders-table">
    <div class="card-header search justify-content-start">
      <custom-select-2
        v-model="searchParams.searchStatus"
        name="status"
        label="Статус"
        :options="statusOptions"
      />
      <custom-select-2
        v-show="getRight('allOrders')"
        v-model="searchParams.searchUser"
        name="user"
        label="Пользователь"
        :settings="usersAjax"
      />
    </div>
    <div class="card-header search justify-content-start">
      <custom-input
        label="Цена от"
        type="number"
        name="priceFrom"
        v-model="searchParams.searchPrice"
      />
      <custom-select-2
        v-show="getRight('allOrders')"
        v-model="searchParams.searchCompany"
        name="company"
        label="Компания"
        :options="['Не выбрано', ...organizationsOptions]"
      />
    </div>
    <div class="card-header search justify-content-start products-search">
      <custom-select-2
        v-show="getRight('allOrders')"
        v-model="searchParams.searchProducts"
        name="products"
        label="Товары"
        :multiple="true"
        :options="productsOptions"
        :labelWidth="12"
        :inputWidth="88"
      />
    </div>
    <div class="card-header search visible-sm justify-content-start">
      <custom-input
        label="Email"
        name="email"
        v-model="searchParams.searchEmail"
        cleanable
      />
    </div>
    <div class="card-header search search__clear-btn">
      <button @click="clearAllSearch" type="button" class="btn btn-w btn-sm">
        Очистить
      </button>
    </div>
    <div class="card-header additional-info justify-content-between">
      <div class="select-count justify-self-end">
        <custom-select
          :labelWidth="40"
          name="rowsCount"
          v-model="countOnPage"
          label="Кол-во заказов на странице"
          :options="[10, 15, 20, 50, 75]"
        />
      </div>
      <div>
        <transition name="fade">
          <div v-if="checkedList.length" class="selected-count">
            Выделено: {{ checkedList.length }}
          </div>
        </transition>
      </div>
    </div>

    <ag-grid-vue
      :columnDefs="columnDefs.value"
      :rowData="rowData"
      :rowHeight="120"
      rowSelection="multiple"
      :headerHeight="90"
      :enableCellTextSelection="true"
      :ensureDomOrder="true"
      :pagination="true"
      :paginationPageSize="countOnPage"
      :defaultColDef="defaultColDef"
      :suppressRowHoverHighlight="true"
      :suppressPaginationPanel="true"
      :suppressRowClickSelection="true"
      :unSortIcon="true"
      :alwaysMultiSort="true"
      :suppressRowTransform="true"
      @row-selected="onRowSelected"
      style="width: 100%"
      domLayout="autoHeight"
      @grid-ready="onGridReady"
      :isExternalFilterPresent="isExternalFilterPresent"
      :doesExternalFilterPass="doesExternalFilterPass"
      @filterChanged="filterChanged"
    ></ag-grid-vue>

    <table-pagination
      :gridApi="gridApi"
      :totalPages="gridApi ? gridApi.paginationGetTotalPages() : 0"
      :countOnPage="countOnPage"
      :activeFilter="activeFilter"
    />
  </div>
  <div class="table-preloader" v-else>
    <preloader />
  </div>
</template>

<script>
import {
  computed,
  onBeforeMount,
  reactive,
  ref,
  watch,
} from "@vue/runtime-core";
import VPagination from "@hennge/vue3-pagination";
import { useStore } from "vuex";
import ProductsRenderer from "@/components/Tables/CellRenderers/ProductsRenderer.vue";
import OrderApprove from "@/components/Tables/CellRenderers/OrderApprove.vue";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import { AgGridVue } from "ag-grid-vue3";
import "ag-grid-community/dist/styles/ag-grid.css";
import CustomSelect from "@/components/Forms/Fields/CustomSelect.vue";
import Preloader from "@/components/Technical/Preloader.vue";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import useCheckRights from "@/mixins/useCheckRights";
import CustomTextFilter from "@/components/Tables/FiltersComponents/CustomTextFilter.vue";
import TablePagination from "@/components/OtherElements/TablePagination.vue";
import useAjaxSelect from "@/mixins/useAjaxSelect";
import orderTableColumns from "./orderTableColumns";
import ButtonRepurchase from "@/components/Tables/CellRenderers/ButtonRepurchase.vue";

export default {
  name: "orders-table",
  components: {
    CustomSelect2,
    VPagination,
    AgGridVue,
    CustomSelect,
    Preloader,
    ProductsRenderer,
    OrderApprove,
    CustomInput,
    CustomTextFilter,
    TablePagination,
    ButtonRepurchase
  },
  setup() {
    const store = useStore(),
      { getRight } = useCheckRights(),
      gridApi = ref(null);

    const onGridReady = (params) => {
      gridApi.value = params.api;
    };
    const countOnPage = ref(localStorage.getItem("countOnPageOrders") || 10),
      searchParams = reactive({
        searchStatus: "Не выбрано",
        searchCompany: "Не выбрано",
        searchUser: "Не выбрано",
        searchPrice: null,
        searchEmail: "",
        searchProducts: [],
      }),
      checkedList = ref([]),
      orderStatuses = {
        new: "Новый",
        sent: "Отправлен",
        paid: "Оплачен",
      },
      statusOptions = ["Не выбрано", "Новый", "Оплачен", "Отправлен"],
      activeFilter = ref(false),
      organizationsOptions = ref([]);
    const productsOptions = ref([]);
    const orders = computed(() => store.state.relations.orders),
      organizations = computed(() => store.state.company.companies),
      products = computed(() => store.state.relations.productsList),
      rowData = computed(() => {
        return orders.value.map((order) => {
          return {
            id: order.id,
            order_id: order.order_id,
            price: order.price,
            status: orderStatuses[order.status],
            products: order.products,
            company:
              order.user && order.user.company ? order.user.company.name : "",
            user: order.user
              ? `${order.user.last_name} ${order.user.first_name} ${order.user.patronymic}`
              : "",
            paid_at: order.paid_at ? order.paid_at.slice(0, 10) : "-",
            created_at: order.created_at ? order.created_at.slice(0, 10) : "-",
            email: order.user ? order.user.email : "",
            entities: order.entities,
            user_id: order.user
          };
        });
      });
    const { usersAjax } = useAjaxSelect(true);
    const columnDefs = reactive(orderTableColumns);
    const defaultColDef = {
      suppressMovable: true,
      flex: 1,
      autoHeight: true,
    };

    const onRowSelected = (e) => {
      const id = e.node.data.id,
        ind = checkedList.value.findIndex((check) => check == id);
      ind !== -1
        ? checkedList.value.splice(ind, 1)
        : checkedList.value.push(id);
    };

    const clearAllSearch = () => {
      searchParams.searchStatus = "Не выбрано";
      searchParams.searchCompany = "Не выбрано";
      searchParams.searchUser = "Не выбрано";
      searchParams.searchPrice = null;
    };

    const isExternalFilterPresent = () => {
      const isPresent =
        searchParams.searchPrice || searchParams.searchProducts.length;
      if (isPresent) activeFilter.value = !activeFilter.value;
      return isPresent;
    };
    const doesExternalFilterPass = (node) => {
      if (!searchParams.searchProducts.length)
        return Number(node.data.price) >= Number(searchParams.searchPrice);
      let isAllProductsInOrder = true;
      for (let i = 0; i < searchParams.searchProducts.length; i++) {
        if (
          !node.data.products.find(
            (product) => product.name === searchParams.searchProducts[i]
          )
        ) {
          isAllProductsInOrder = false;
          break;
        }
      }
      return (
        isAllProductsInOrder &&
        Number(node.data.price) >= Number(searchParams.searchPrice)
      );
    };
    const filterChanged = () => {
      activeFilter.value = !activeFilter.value;
    };

    onBeforeMount(() => {
      store.dispatch("relations/getOrders");
      store.dispatch("company/getCompanies").then(() => {
        organizationsOptions.value = organizations.value.map(
          (company) => company.name
        );
      });
      store.dispatch("relations/getProductsList").then(() => {
        const filterSet = new Set();
        products.value.forEach((item) => filterSet.add(item.name));
        productsOptions.value = Array.from(filterSet);
      });
    });

    watch(searchParams, () => {
      if (
        searchParams.searchStatus !== "Не выбрано" ||
        searchParams.searchCompany !== "Не выбрано" ||
        searchParams.searchUser !== "Не выбрано" ||
        searchParams.searchEmail
      ) {
        gridApi.value.setQuickFilter(
          `${
            searchParams.searchStatus !== "Не выбрано"
              ? searchParams.searchStatus
              : ""
          } ${
            searchParams.searchCompany !== "Не выбрано"
              ? searchParams.searchCompany
              : ""
          } ${
            searchParams.searchUser !== "Не выбрано"
              ? searchParams.searchUser
              : ""
          } ${searchParams.searchEmail ? searchParams.searchEmail : ""}`
        );
      } else {
        gridApi.value.setQuickFilter(null);
      }
      gridApi.value.onFilterChanged();
      activeFilter.value = !activeFilter.value;
    });

    watch(countOnPage, () => {
      localStorage.setItem("countOnPageOrders", countOnPage.value);
    });

    return {
      countOnPage,
      checkedList,
      statusOptions,
      productsOptions,
      searchParams,
      activeFilter,
      clearAllSearch,
      orders,
      organizationsOptions,
      usersAjax,

      getRight,

      isExternalFilterPresent,
      doesExternalFilterPass,

      columnDefs,
      rowData,
      defaultColDef,
      onRowSelected,
      gridApi,
      onGridReady,
      filterChanged,
      ButtonRepurchase
    };
  },
};
</script>

<style lang="scss">
.orders-table {
  .ag-body-viewport {
    min-height: 250px;
  }
}
</style>

<style lang="scss" scoped>
.checkbox-cell {
  padding-bottom: 4px;
}

.orders-table {
  .card-header {
    margin: 0;
    padding: 10px 0;
  }

  .select-count {
    min-width: 345px;
  }

  .search__clear-btn {
    padding-left: 10px;
  }

  @media (min-width: 1550px) {
    .card-header {
      .form-group:not(:last-child) {
        margin-right: 15px;
      }
      .form-group {
        min-width: 445px;
      }
    }

    .products-search {
      .form-group {
        min-width: 900px;
      }
    }
  }

  @media (max-width: 1550px) {
    .head-sort {
      flex-direction: column;
      align-items: flex-start;
    }
    .reset-btn {
      margin-top: 15px;
      margin-left: 0;
    }

    .search {
      display: flex;
      flex-direction: column;
      .form-group {
        width: 100%;
        max-width: 100%;
      }

      &__clear-btn {
        padding: 0;
        align-items: flex-end;
        padding-right: 15px;
      }
    }

    .select-count {
      min-width: 500px;
    }
  }

  @media (max-width: 978px) {
    .card-header {
      padding: 2px 0;
    }
    .search {
      &__clear-btn {
        padding-right: 10px;
        margin-top: 8px;
      }
    }
    .additional-info {
      flex-direction: column;
      align-items: flex-start;
    }
    .select-count {
      min-width: 100%;
      padding-left: 0;
    }

    .selected-count {
      margin-top: 10px;
    }
  }
}
</style>
