<template>
  <router-link v-if="getRightSuperAdmin('repurchase')" @click="repurchase" to="/orders/create" class="btn btn-accent">
    Купить повторно</router-link>
</template>
<script>
import { useStore } from "vuex"; 
import useCheckRights from "../../../mixins/useCheckRights";
export default {
  data(props) {
    const store = useStore(),
    { getRightSuperAdmin } = useCheckRights();
    const repurchase = () => {
      store.commit('relations/setCurrentOrder', props.params.data)
    }
    return {
      repurchase,
      getRightSuperAdmin,
    }
  },
}
</script>